import React from "react";

export default class InfeedAds extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <ins
        class="adsbygoogle"
        style={{ display: "block",marginBottom:"10px" }}
        data-ad-format="fluid"
        data-ad-layout-key="-hz+a-11-5i+ed"
        data-ad-client="ca-pub-9365813185370392"
        data-ad-slot="3250171216"
        // data-adtest="on"
      ></ins>
    );
  }
}
