import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { baseUrl } from "../../utils/constants";
import InfeedAds from "../Ads/InFeedAds";
const LandscapeCard = (props) => {
  const location = useLocation();
  const [datas, setDatas] = useState([]);
  const [additionalData, setAdditionalData] = useState([props.additionalData]);
  const [category, setCategory] = useState(props.category);

  let navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const clickHandler = (urlHash, title) => {
    if (location.pathname.includes("minus-one")) {
      const finalRedirectUrl = new URL(
        `/minus-one/${urlHash}/${title}`,
        window.location.origin
      );
      window.location.href = finalRedirectUrl.toString();
      // navigate(`/minus-one/${urlHash}`, { state: { category } });
    } else {
      navigate(`/${category}/detail/${urlHash}`, { state: { category } });
    }
  };

  const getArticleList = async () => {
    try {
      const body = [
        {
          key: "CATEGORY",
          value: props.category === "Home" ? null : props.category,
        },
      ];
      const res = await axios.post(
        "https://api.vistory.in/vistory/api/v1/articles/getArticles",
        body,
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            direction: "ASC",
            pageNumber: props.pageNum + 1,
            pageSize: 8,
          },
        }
      );
      setDatas(res.data.responseObject.content);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getArticleList();
  }, [props.additionalData]);
  const filteredData = datas
    .filter(
      (data) =>
        !additionalData.some(
          (additional) => additional.urlHash === data.urlHash
        ) && data.urlHash !== props.article.urlHash
    )
    .slice(0, 4);

  const newsCards = filteredData.map((data, index) => (
    <React.Fragment key={index}>
      {/* {(index === 2 || index === 3) && <InfeedAds />} */}

      <div
        className="news-card"
        key={index}
        onClick={() => clickHandler(data.urlHash, data.title)}
      >
        <div className="card-left-side">
          <p>{data.title}</p>
        </div>
        <div className="card-right-side">
          {data.mainImage &&
          data.mainImage.includes(
            "https://vistory.s3.ap-south-1.amazonaws.com/"
          ) ? (
            <img
              src={data.mainImage}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fit",
              }}
            />
          ) : data.mainImage ? (
            <img
              src={baseUrl + data.mainImage}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fit",
              }}
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  ));

  return (
    <>
      <div className="news-container">
        <div className="news-card-container">
          <p>Recommended Articles</p>
          {/* <InfeedAds /> */}
          {newsCards}

          {/* {datas.map(
            (data, index) =>
              data.urlHash !==
              props.additionalData.urlHash(
                <div className="news-card" key={index}>
                  <div className="card-left-side">
                    <p>{data.title}</p>{" "}
                  </div>
                  <div className="card-right-side">
                    {data.mainImage.includes(
                      "https://vistory.s3.ap-south-1.amazonaws.com/"
                    ) ? (
                      <img
                        src={data.mainImage}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fit",
                        }}
                      />
                    ) : data.mainImage ? (
                      <img
                        src={baseUrl + data.mainImage}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fit",
                        }}
                      />
                    ) : null} */}
          {/* <img
                  className="news-image"
                  src={data.mainImage} // Assuming this should vary for each article
                  alt="News"
                /> */}
          {/* </div>
                </div>
              )
          )} */}

          {/* <div className="news-card">
            <div className="card-left-side">
              <p>
                VELAS, MAHARASHTRA – DISCOVER THE CHARMS OF COASTAL LIVING
                VELAS, MAHARASHTRA – DISCOVER THE CHARMS OF COASTAL LIVING
              </p>
            </div>
            <div className="card-right-side">
              <img
                className="news-image"
                src={props.article.mainImage}
                alt="News"
              />
            </div>
          </div>
          <div className="news-card">
            <div className="card-left-side">
              <p>VELAS, MAHARASHTRA – DISCOVER THE CHARMS OF COASTAL LIVING</p>
            </div>
            <div className="card-right-side">
              <img
                className="news-image"
                src={props.article.mainImage}
                alt="News"
              />
            </div>
          </div>
          <div className="news-card">
            <div className="card-left-side">
              <p>VELAS, MAHARASHTRA – DISCOVER THE CHARMS OF COASTAL LIVING</p>
            </div>
            <div className="card-right-side">
              <img
                src={props.article.mainImage}
                className="news-image"
                alt="News"
              />
            </div>
          </div>
          <div className="news-card">
            <div className="card-left-side">
              <p>VELAS, MAHARASHTRA – DISCOVER THE CHARMS OF COASTAL LIVING</p>
            </div>
            <div className="card-right-side">
              <img
                src={props.article.mainImage}
                className="news-image"
                alt="News"
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default LandscapeCard;
