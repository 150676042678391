import ShareIcon from "@mui/icons-material/Share";
import { Container, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useInView } from "react-intersection-observer";
import { MgidWidget } from "react-mgid-widget";
import { useLocation, useParams } from "react-router-dom";
import AdComponent2 from "../../components/Ads/adComponent2";
import ArticleCard from "../../components/Card";
import { CardHeader } from "../../components/Card/style";
import Footer from "../../components/Footer";
import LandscapeCard from "../../components/LandscapeCard/Landscape";
import ArticleCardLoading from "../../components/Loading/ArticleDetail";
import LoadingCard from "../../components/Loading/Card";
import { baseUrl } from "../../utils/constants";
import { storePreviousArticlesId } from "../../utils/function";
import { pxToRem } from "../../utils/pxToRem";
import { DataContent } from "./style";
import MGIDBody from "../../components/Ads/Mgidbody";

const ArticleDetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let { category } = useParams();
  const [data, setData] = useState({});
  const [articleLoading, setArticleLoading] = useState(true);
  const location = useLocation();
  let { id } = useParams();
  const [showArticleLower, setShowArticleLower] = useState(false);
  const [scrolledToStories, setScrolledToStories] = useState(0);
  const [viewedArticleIds, setViewedArticleIds] = useState([]);
  const storiesRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginateArticleList, setPaginateArticleList] = useState([]);
  const { ref, inView } = useInView();
  const [isLastArticlePage, setIsLastArticlePage] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [datas, setDatas] = useState([]);
  const [openedArticles, setOpenedArticles] = useState([]);
  const [singleArticleLoading, setSingleArticleLoading] = useState(true);
  const [article, setArticle] = useState({});

  const gridContainerStyle = {
    padding: { xs: pxToRem(10), md: `${pxToRem(20)} ${pxToRem(40)}` },
    backgroundColor:
      "background: linear-gradient(180deg, rgba(255, 255, 255, 0) -9.97%, #ECF0F7 100%)",
  };

  const getSingleArticle = async () => {
    try {
      setSingleArticleLoading(true);
      let response = await axios.get(
        `${baseUrl}/vistory/api/v1/articles/getArticleByHash`,
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            articleId: id,
          },
        }
      );
      setArticle(response.data.responseObject);
      setSingleArticleLoading(false);
    } catch (error) {
      console.error(error);
      setSingleArticleLoading(false);
    }
  };
  const getArticle = async () => {
    try {
      setArticleLoading(true);
      let response = await axios.post(
        `${baseUrl}/vistory/api/v1/articles/getDetailedArticles`,
        [
          {
            key: "CATEGORY",
            value: category === "Home" ? null : category,
          },
          {
            key: "ARTICLE_NOT_IN",
            value:
              viewedArticleIds.toString() != ""
                ? viewedArticleIds.toString()
                : id,
          },
        ],
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            direction: "ASC",
            pageNumber: 0,
            pageSize: 4,
          },
        }
      );
      setDatas(response.data.responseObject.content);
      setArticleLoading(false);
    } catch (error) {
      setArticleLoading(false);
    }
  };
  const getArticleList = async (pageNumber, paginate) => {
    try {
      if (paginate) setIsPaginating(true);
      const body = [
        {
          key: "CATEGORY",
          value: category === "Home" ? null : category,
        },
        {
          key: "ARTICLE_NOT_IN",
          value: viewedArticleIds.toString(),
        },
      ];
      const res = await axios.post(
        `${baseUrl}/vistory/api/v1/articles/getArticles`,
        body,
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            direction: "ASC",
            pageNumber: paginate && pageNumber > 0 ? pageNumber : 0,
            pageSize: 6,
          },
        }
      );
      if (paginate) {
        setPaginateArticleList((prev) => [
          ...prev,
          ...res.data.responseObject.content,
        ]);
        if (res.data.responseObject.content.length > 0)
          setPageNumber(pageNumber + 1);
        setIsPaginating(false);
        return;
      }
      setPageNumber(1);
      setPaginateArticleList([]);
      setIsLastArticlePage(!res.data.responseObject.empty);
    } catch (err) {
      console.error(err);
      setIsPaginating(false);
    }
  };
  useEffect(() => {
    storePreviousArticlesId(id, setViewedArticleIds);
  }, []);
  useEffect(() => {
    storePreviousArticlesId(id, setViewedArticleIds);
    getArticle();
  }, [id]);

  const CardTitleStyle = isMobile
    ? {
        fontFamily: "Montserrat",
        fontSize: pxToRem(18),
        fontWeight: "600",
        margin: 0,
        padding: 0,
      }
    : {
        fontFamily: "Montserrat",
        fontSize: pxToRem(30),
        fontWeight: "600",
        margin: 0,
        padding: 0,
      };

  const shareButtonHandler = (data) => {
    navigator.share({
      title: data.title,
      url: `/${category}/detail/${data.urlHash}`,
      text: data.description,
    });
  };

  const Description = styled("div")(({ theme }) => ({
    position: "relative",
    opacity: "1",
    display: showArticleLower ? "none" : "block",
    "&::after": {
      content: '""',
      width: "100%",
      height: pxToRem(40),
      display: "inline-block",
      marginTop: pxToRem(30),
      fontSize: 24,
      backgroundColor: "#fff",
      opacity: showArticleLower ? "0" : "",
      boxShadow: showArticleLower ? "none" : "1px 2px 50px 47px #fff",
    },
  }));

  const ImageContainer = styled("div")(({ theme }) => ({
    height: pxToRem(500),
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  }));

  const ShowButton = styled("div")(({ theme }) => ({
    zIndex: "1",
    position: "absolute",
    top: "50%",
    left: "45%",
    opacity: "1",
    backgroundColor: "#F3854D",
    color: "#fff",
    padding: "10px 15px",
    borderRadius: "20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      left: "35%",
      top: "78%",
    },
  }));

  useEffect(() => {
    if (
      storiesRef.current &&
      !articleLoading &&
      !scrolledToStories &&
      location.state?.storyClosed
    ) {
      storiesRef.current.scrollIntoView({ behavior: "smooth" });
      setScrolledToStories(true);
    }
  }, [articleLoading]);

  useEffect(() => {
    storePreviousArticlesId(id, setViewedArticleIds);
    if (inView) getArticleList(pageNumber, true);
  }, [inView, category, id]);
  useEffect(() => {
    storePreviousArticlesId(id, setViewedArticleIds);
    getSingleArticle();
    getArticleList(0, false);
  }, [category, id]);
  const adComponentHTML = "";
  // ReactDOMServer.renderToStaticMarkup(<AdComponent2 />);
  const WidgetHtml = "";
  //  ReactDOMServer.renderToStaticMarkup(<MGIDBody />);

  const processHTMLContent = (htmlBody) => {
    // const content = htmlBody;
    // const splitContent = content.split(/(<p[^>]*>.*?<\/p>)/g);
    // let modifiedContent = "";
    // let strongTagCount = 0;
    // for (let i = 0; i < splitContent.length; i++) {
    //   if (splitContent[i].startsWith("<p")) {
    //     strongTagCount++;
    //     modifiedContent += splitContent[i];
    //     if (strongTagCount % 5 === 0) {
    //       modifiedContent += adComponentHTML;
    //     }
    //   } else {
    //     modifiedContent += splitContent[i];
    //   }
    // }
    // return { __html: modifiedContent };

    // const hasAds = htmlBody.includes("<>ads</>");
    // const hasAds = htmlBody.toLowerCase().includes("<>ads</>");
    // const hasAds = /<>\s*ads\s*<\/>/.test(htmlBody);

    if (htmlBody.includes("adsplacement")) {
      // let modifiedContent = htmlBody.split("adsplacement");
      let modifiedContent = htmlBody.split(/(adsplacement)/);
      modifiedContent = modifiedContent.map((part, index) => {
        if (index % 2 === 1) {
          return adComponentHTML;
        } else {
          return part;
        }
      });
      const resultHTML = modifiedContent.map((part) => part.trim()).join("");
      return { __html: resultHTML };
    } else {
      const splitContent = htmlBody.split(/(<p[^>]*>.*?<\/p>)/g);
      let modifiedContent = "";
      let strongTagCount = 0;
      for (let i = 0; i < splitContent.length; i++) {
        if (splitContent[i].startsWith("<p")) {
          strongTagCount++;
          modifiedContent += splitContent[i];
          if (strongTagCount % 5 === 0) {
            modifiedContent += adComponentHTML;
          }
        } else {
          modifiedContent += splitContent[i];
        }
      }

      return { __html: modifiedContent };
    }
  };

  const showParticularArticle = (hashId) => {
    if (openedArticles.includes(hashId)) {
      setOpenedArticles(openedArticles.filter((id) => id !== hashId));
    } else {
      setOpenedArticles([...openedArticles, hashId]);
    }
  };

  return (
    <>
      {singleArticleLoading ? (
        <ArticleCardLoading />
      ) : (
        <div style={{ marginTop: pxToRem(78) }}>
          {/* <MGIDScriptComponent /> */}
          <div style={{ marginBottom: "50px", marginTop: "100px" }}>
            <AdComponent2 />
            {/* <MGIDScriptComponent /> */}
          </div>
          <ImageContainer>
            {article.mainImage.includes(
              "https://vistory.s3.ap-south-1.amazonaws.com/"
            ) ? (
              <img
                src={article.mainImage}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "fit",
                }}
              />
            ) : article.mainImage ? (
              <img
                src={baseUrl + article.mainImage}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "fit",
                }}
              />
            ) : (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={250}
              />
            )}
          </ImageContainer>

          <Container maxWidth="lg">
            <CardHeader style={{ paddingTop: "10px" }}>
              <h6 style={CardTitleStyle}>{article.title}</h6>
            </CardHeader>
            <CardHeader>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={baseUrl + article.publisherLogo}
                  alt=""
                  height={isMobile ? "25px" : "30px"}
                />
                <span
                  style={{
                    fontSize: "14px",
                    paddingLeft: "5px",
                    fontWeight: 700,
                    textTransform: "capitalize",
                  }}
                >
                  {article.publisherName}
                </span>
              </div>
              <IconButton onClick={() => shareButtonHandler(article)}>
                <div style={{ width: "15px" }}>
                  <ShareIcon />
                </div>
              </IconButton>
            </CardHeader>
            <div>
              {article.category === "AudPod" ? (
                <AudioPlayer
                  style={{ paddingTop: pxToRem(10) }}
                  autoPlay
                  src={article.audioFile ? baseUrl + article.audioFile : ""}
                />
              ) : (
                !openedArticles.includes(article.urlHash) && (
                  <>
                    <Description>
                      {article.description}
                      <ShowButton
                        onClick={() => showParticularArticle(article.urlHash)}
                      >
                        Read More
                      </ShowButton>
                    </Description>
                  </>
                )
              )}
            </div>
            {openedArticles.includes(article.urlHash) && (
              <>
                <DataContent
                  dangerouslySetInnerHTML={processHTMLContent(
                    article.contentBody
                  )}
                ></DataContent>
              </>
            )}
          </Container>
        </div>
      )}
      {/* multiple articles expanded */}

      {/* <AdComponent2 /> */}
      {articleLoading ? (
        <ArticleCardLoading />
      ) : (
        datas.map((data, index) => (
          <div style={{ marginTop: pxToRem(20) }} key={index}>
            {
              <div style={{ padding: "20px" }}>
                <hr style={{ backgroundColor: "#a6afbd" }} />
              </div>
            }

            <Container maxWidth="lg">
              <MgidWidget
                id="M782567ScriptRootC1577987"
                src="https://jsc.mgid.com/v/i/vistory.mobi.1577987.js"
              />
              <LandscapeCard
                article={article}
                additionalData={datas}
                category={category}
                pageNum={index}
              />

              <CardHeader style={{ paddingTop: "10px" }}>
                <h1 style={CardTitleStyle}>{data.title}</h1>
              </CardHeader>

              <CardHeader>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={baseUrl + data.publisherLogo}
                    alt=""
                    height={isMobile ? "20px" : "30px"}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      paddingLeft: "5px",
                      fontWeight: 700,
                      textTransform: "capitalize",
                    }}
                  >
                    {data.publisherName}
                  </span>
                </div>
                <IconButton onClick={() => shareButtonHandler(data)}>
                  <div style={{ width: "15px" }}>
                    <ShareIcon />
                  </div>
                </IconButton>
              </CardHeader>
              {/* <MGIDBody /> */}
              {/* <AdComponent2 /> */}
              {/* <MgidWidget
                id="M782567ScriptRootC1431267"
                src="https://jsc.mgid.com/v/i/vistory.mobi.1431267.js"
              /> */}
              <div>
                {data.category === "AudPod" ? (
                  <AudioPlayer
                    style={{ paddingTop: pxToRem(10) }}
                    autoPlay
                    src={data.audioFile ? baseUrl + data.audioFile : ""}
                  />
                ) : (
                  !openedArticles.includes(data.urlHash) && (
                    <Description>
                      {data.description}
                      <ShowButton
                        onClick={() => showParticularArticle(data.urlHash)}
                      >
                        Read More
                      </ShowButton>
                    </Description>
                  )
                )}
              </div>
              {openedArticles.includes(data.urlHash) && (
                <DataContent
                  dangerouslySetInnerHTML={processHTMLContent(data.contentBody)}
                ></DataContent>
              )}
            </Container>
            {/* <MGIDBody /> */}
            {index === 0 ? (
              <MgidWidget
                id="M782567ScriptRootC1590356"
                src="https://jsc.mgid.com/v/i/vistory.mobi.1590356.js"
              />
            ) : index === 1 ? (
              <MgidWidget
                id="M782567ScriptRootC1590357"
                src="https://jsc.mgid.com/v/i/vistory.mobi.1590357.js"
              />
            ) : index === 2 ? (
              <MgidWidget
                id="M782567ScriptRootC1590358"
                src="https://jsc.mgid.com/v/i/vistory.mobi.1590358.js"
              />
            ) : index === 3 ? (
              <MgidWidget
                id="M782567ScriptRootC1590360"
                src="https://jsc.mgid.com/v/i/vistory.mobi.1590360.js"
              />
            ) : null}
          </div>
        ))
      )}

      {/* ---trending component here--- */}
      {/* <Trending margin={`${pxToRem(36)} 0`} /> */}
      {/* ---stories component here--- */}
      {/* <div ref={storiesRef}>
        <Stories
          margin={`${pxToRem(36)} 0`}
          refVal={paginateArticleList.length === 0 ? ref : null}
        />
      </div> */}
      {/* ---pagination component here--- */}

      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={gridContainerStyle}
      >
        {paginateArticleList.map((element, idx) => {
          const last = paginateArticleList.length - 1;
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              key={`paginate-card-container-${idx}`}
              ref={last === idx && paginateArticleList.length > 0 ? ref : null}
            >
              <ArticleCard
                urlHash={element.urlHash}
                title={element.title}
                description={element.description}
                type={element.type}
                category={element.category}
                imageUrl={element.mainImage}
                key={`paginate-card-${idx}`}
                logo={element.publisherLogo}
                publisherName={element.publisherName}
              />
            </Grid>
          );
        })}
        {isPaginating &&
          Array.from(isMobile ? new Array(1) : new Array(3)).map(
            (el, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  key={"paginate-loading" + index}
                >
                  <LoadingCard />
                </Grid>
              );
            }
          )}
      </Grid>
      {isLastArticlePage && <Footer />}
    </>
  );
};

export default ArticleDetail;
